import { subscribeChannel, unsubscribeChannel } from './duplexer-actions';
import createAction from '../../common/services/create-action';
import { showNewPostMessageDebounced } from './show-new-post-message';
import { DUPLEXER_CHANNELS } from '../../universal/constants/duplexer';
import { fetchPost } from './fetch-post';

export const emitOpenCategory = (categoryId) => (dispatch) => {
  dispatch(subscribeChannel({ channelName: DUPLEXER_CHANNELS.CATEGORY, resourceId: categoryId }));
};

export const emitCloseCategory = (categoryId) => (dispatch) => {
  dispatch(unsubscribeChannel({ channelName: DUPLEXER_CHANNELS.CATEGORY, resourceId: categoryId }));
};

export const UPDATE_POST_COUNTERS = 'post/UPDATE_POST_COUNTERS';
export const UPDATE_CATEGORY_COUNTERS = 'category/UPDATE_CATEGORY_COUNTERS';

export const newPost = (payload) => (dispatch) => {
  dispatch(fetchPost(payload.slug));
  dispatch(showNewPostMessageDebounced(payload._id));
};

export const updatePostCounters = createAction(
  UPDATE_POST_COUNTERS,
  (payload) => payload,
  () => ({ throttle: { batch: 'sockets' } }),
);

export const updateCategoryCounters = createAction(
  UPDATE_CATEGORY_COUNTERS,
  (payload) => payload,
  () => ({ throttle: { batch: 'sockets' } }),
);
