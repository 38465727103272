export const hasSuspiciousFields = (contentFeatures) => {
  const suspiciousFields = ['hasPhoneNumber', 'hasEmail', 'hasLink', 'hasInstantMessagingAddress'];

  return suspiciousFields.some((f) => contentFeatures[f] === true);
};

export const textToDraftContent = (text) => {
  return {
    blocks: [
      {
        text,
      },
    ],
    entityMap: {},
  };
};
